import React from "react"

import { PageLayout } from "@components/layouts"
import SEO from "@components/seo"
import TextBlock from "@components/textBlock"
import SingleColumnBlock from "@components/singleColumnBlock"
import TripleColumnBlock from "@components/tripleColumnBlock"
import PageWrapper from "@components/pageWrapper"
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import ReactPlayer from 'react-player'

import {
  QuickLinkButton,
} from "@components/buttons"

import {
  LearnMoreButton,
} from "@components/buttons"

import {
  FormWrapper,
  RequestMoreInfo,
} from "@components/Forms"

const IndexPage = () => {
  return (
    <PageLayout>
      <SEO
        title="Social Media Communities - Melissa Howell - Websuasion"
        ogDescription="Melissa Howell offers extensive experience as a Strategic Marketing Consultant and Social Media Manager. Focusing on Instagram, Twitter, Facebook, Pinterest, Google+ and Youtube, she creates personalized content, develops branding, and writes newsletters and blogs."
        image="/images/podcast/season-one/melissa-howell.jpg"
      />
      <TextBlock
        textPadded
        textLeft={
          <div>
						<h1>Building Social Media Communities with Melissa Howell</h1>
						<p>
							Melissa Howell offers extensive experience as a Strategic Marketing Consultant and Social Media Manager. Focusing on Instagram, Twitter, Facebook, Pinterest, Google+ and Youtube, she creates personalized content, develops branding, and writes newsletters and blogs. She is focused on helping business owners find their voice in this digital world!
						</p>
						<p>
			        <QuickLinkButton
			          content={"Winterstorm Marketing"}
			          url={"https://winterstormmarketing.com/"}
			          icon={"linkify"}
			        />
						</p>
						<p>
			        <QuickLinkButton
			          content={"SamUrgency"}
			          url={"https://samurgency.org/"}
			          icon={"linkify"}
			        />
						</p>
						<p>
							In this episode of Websuasion Conversation, we discuss consistency, engagement, and building a community of followers.
						</p>
					</div>
        }
        textRight={
          <div className="centered">
            <img src="/images/podcast/season-one/melissa-howell.jpg" className="rounded-square" alt="Building Social Media Communities with Melissa Howell" width="300" />
					</div>
        }
      />
			<PageWrapper color="white">
	      <SingleColumnBlock
	        text={
	          <div>
							<h2>Listen Now</h2>
						  <AudioPlayer
						    src="https://websuasion-conversation.s3.amazonaws.com/WebsuasionConversationEpisode019-MelissaHowell.mp3"
						  />
							<h3>Subscribe to the podcast</h3>
							<p>
								<a href="https://itunes.apple.com/us/podcast/websuasion-conversation/id1454464479" target="_blank">
									<img src="/images/podcast/apple-podcasts-150x150.png" className="icon" alt="Websuasion Conversasion on Apple Podcasts" width="50" />
								</a>
								<a href="https://open.spotify.com/show/521lym6rKJPoWQZbeUROuG" target="_blank">
									<img src="/images/podcast/spotify-150x150.png" className="icon" alt="Websuasion Conversasion on Spotify" width="50" />
								</a>
							</p>
							<p>
								Podcast RSS feed:<br /><br />

								<span className="red">https://websuasion.com/podcast.xml</span>
							</p>
	          </div>
	        }
	      />
			</PageWrapper>
			<PageWrapper color="charcole">
	      <TextBlock
	        textPadded
	        textLeft={
	          <div>
							<h2>Introduction</h2>
							<p>
								Melissa tells us about the relationship between marketing and cultural anthropology.
							</p>
	          </div>
	        }
	        textRight={
			      <div className='player-wrapper'>
			        <ReactPlayer
			          className='react-player'
			          url='https://www.youtube.com/embed/cWBq3TjD198'
			          width='100%'
			          height='100%'
			        />
			      </div>
	        }
	      />
			</PageWrapper>
			<PageWrapper color="white">
	      <TextBlock
	        textPadded
	        textLeft={
	          <div>
							<h2>Marketing for a Nonprofit Organization</h2>
							<p>
								Melissa Howell tells us about her work with SamUrgency, a nonprofit organization that provides financial assistance for medical expenses for Samoyeds.
							</p>
	          </div>
	        }
	        textRight={
			      <div className='player-wrapper'>
			        <ReactPlayer
			          className='react-player'
			          url='https://www.youtube.com/embed/4vCJohp6pZE'
			          width='100%'
			          height='100%'
			        />
			      </div>
	        }
	      />
			</PageWrapper>
			<PageWrapper color="charcole">
	      <TextBlock
	        textPadded
	        textLeft={
	          <div>
							<h2>Instagram Engagement</h2>
							<p>
								Melissa talks to us about the recent changes to Instagram and the importance of having organic followers who engage with your account.
							</p>
	          </div>
	        }
	        textRight={
			      <div className='player-wrapper'>
			        <ReactPlayer
			          className='react-player'
			          url='https://www.youtube.com/embed/oO8i1MmT13M'
			          width='100%'
			          height='100%'
			        />
			      </div>
	        }
	      />
			</PageWrapper>
			<PageWrapper color="white">
	      <TextBlock
	        textPadded
	        textLeft={
	          <div>
							<h2>Building A Community of Followers</h2>
							<p>
								Melissa Howell talks about engaging with like-minded people.
							</p>
	          </div>
	        }
	        textRight={
			      <div className='player-wrapper'>
			        <ReactPlayer
			          className='react-player'
			          url='https://www.youtube.com/embed/pCWJWyVRglM'
			          width='100%'
			          height='100%'
			        />
			      </div>
	        }
	      />
			</PageWrapper>
			<PageWrapper color="charcole">
	      <TextBlock
	        textPadded
	        textLeft={
	          <div>
							<h2>Being the Voice of Your Clients</h2>
							<p>
								Melissa discusses how social media should reflect the personality of the business owner.
							</p>
	          </div>
	        }
	        textRight={
			      <div className='player-wrapper'>
			        <ReactPlayer
			          className='react-player'
			          url='https://www.youtube.com/embed/jQDuIaFMulM'
			          width='100%'
			          height='100%'
			        />
			      </div>
	        }
	      />
			</PageWrapper>
			<PageWrapper color="white">
	      <TextBlock
	        textPadded
	        textLeft={
	          <div>
							<h2>Being the Expert</h2>
							<p>
								Melissa explains how LinkedIn has changed and can be a very useful networking tool.
							</p>
	          </div>
	        }
	        textRight={
			      <div className='player-wrapper'>
			        <ReactPlayer
			          className='react-player'
			          url='https://www.youtube.com/embed/CvKD5A84ZOk'
			          width='100%'
			          height='100%'
			        />
			      </div>
	        }
	      />
			</PageWrapper>
			<PageWrapper color="charcole">
	      <TextBlock
	        textPadded
	        textLeft={
	          <div>
							<h2>Avoiding Controversial Behavior on Social Media</h2>
							<p>
								Melissa Howell tells us why she discourages controversial behavior on social media and how it can affect business.
							</p>
	          </div>
	        }
	        textRight={
			      <div className='player-wrapper'>
			        <ReactPlayer
			          className='react-player'
			          url='https://www.youtube.com/embed/DAcFo5KlRH0'
			          width='100%'
			          height='100%'
			        />
			      </div>
	        }
	      />
			</PageWrapper>
			<PageWrapper color="white">
	      <TextBlock
	        textPadded
	        textLeft={
	          <div>
							<h2>Keeping A Client Database</h2>
							<p>
								Melissa discusses the importance of maintaining a client database rather than relying solely on social media.
							</p>
	          </div>
	        }
	        textRight={
			      <div className='player-wrapper'>
			        <ReactPlayer
			          className='react-player'
			          url='https://www.youtube.com/embed/x50WoAIrF9g'
			          width='100%'
			          height='100%'
			        />
			      </div>
	        }
	      />
			</PageWrapper>
			<PageWrapper color="charcole">
	      <TextBlock
	        textPadded
	        textLeft={
	          <div>
							<h2>Consistency is Everything</h2>
							<p>
								Melissa tells us the best time to send out a newsletter and the importance of being consistent.
							</p>
	          </div>
	        }
	        textRight={
			      <div className='player-wrapper'>
			        <ReactPlayer
			          className='react-player'
			          url='https://www.youtube.com/embed/A0-HbHPcO-4'
			          width='100%'
			          height='100%'
			        />
			      </div>
	        }
	      />
			</PageWrapper>
			<PageWrapper color="white">
	      <TextBlock
	        textPadded
	        textLeft={
	          <div>
							<h2>Building and Maintaining Your Facebook Page</h2>
							<p>
								Melissa explains how building and maintaining a Facebook page for your business is a crucial step before beginning a marketing campaign.
							</p>
	          </div>
	        }
	        textRight={
			      <div className='player-wrapper'>
			        <ReactPlayer
			          className='react-player'
			          url='https://www.youtube.com/embed/v0UuxDOVcPM'
			          width='100%'
			          height='100%'
			        />
			      </div>
	        }
	      />
			</PageWrapper>
			<PageWrapper color="charcole">
	      <TextBlock
	        textPadded
	        textLeft={
	          <div>
							<h2>Identifying Your Ideal Client</h2>
							<p>
								Melissa Howell talks to us about the process of helping businesses identify their ideal clients.
							</p>
	          </div>
	        }
	        textRight={
			      <div className='player-wrapper'>
			        <ReactPlayer
			          className='react-player'
			          url='https://www.youtube.com/embed/2N927KM-iQE'
			          width='100%'
			          height='100%'
			        />
			      </div>
	        }
	      />
			</PageWrapper>
			<PageWrapper color="white">
	      <TextBlock
	        textPadded
	        textLeft={
	          <div>
							<h2>App Developer Checklist</h2>
							<p>
								You've been busy building your business, not learning all the ins and outs of application development. There's no reason you should know what it takes to develop a quality, maintainable application and bring it to market. But, before you contract with any firm (including ours), you should understand what is considered the standard. So, we've developed a handy two-page checklist. It will arm you the questions you need to ask before you move forward.
							</p>
						  <QuickLinkButton
						              content={"Get Your Copy"}
						              url={"/files/developer-checklist.pdf"}
						              icon={"file"}
						            />
	          </div>
	        }
	        textRight={
	          <div>
							<img src="/images/podcast/season-one/app-developer-checklist.png" className="rounded-image" alt="App Developer Checklist" width="620" />
	          </div>
	        }
	      />
			</PageWrapper>
			<PageWrapper color="white">
	      <SingleColumnBlock
	        textPadded
	        text={
	          <div>
	            <h4>Let's Talk!</h4>
	            <p>"Data. Tech. Team." Those are the words we live by at websuasion. Our services foster growth and scalability for your business - all while maintaining your brand. We are an indispensable ally for you as you navigate online marketing. Let's talk. We'd love to know more about what you do and how we can solve your pain points.</p>
	            <RequestMoreInfo />
	          </div>
	        }
	      />
			</PageWrapper>
    </PageLayout>
  )
}

export default IndexPage
